@import '@mozaic-ds/styles/settings-tools/all-settings';
.component-marketingfragment{
  margin-bottom: $mu200;
  &__compaign-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.8rem;
    color: white;
    column-gap: $mu250;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: $mu050;
    min-height: 2.125rem;
    p{
      color:inherit;
      text-align: center;
    }
    a, a:visited{
      color:inherit;
      white-space: nowrap;
    }
  }
  &__img-link{
    display: flex;
    justify-content: center;
    align-content: center;
    max-width: inherit;
    img{
      max-height: 7.5rem;
      width: 100%;
    }
    picture{
      width: 100%;
    }
  }
  &__text-link-label-countdown {
    min-height: inherit;
  }
  &__text-link-label {
    min-height: inherit;
  }

}
@media (max-width: 679px) {
  .component-marketingfragment{
    padding: 0 1rem;
    &__compaign-container {
      max-height: 4.5rem;
    }
    &__img-link{
      img{
        max-height: 10.5rem;
      }
    }
  }
}
@media screen and (max-width: 1023px) {
  .component-marketingfragment > .ml-container {
    padding-left: 0;
    padding-right: 0;
  }
}
